header.main {
  box-shadow: 0 0 40px rgba(0, 0, 0, .3);

  @include media-breakpoint-up(lg) {
    top: -50px !important;
    padding-top: 100px;
    transform: rotate(-1deg);
    //overflow: hidden;
    background: $white;
  }
}

.navbar-brand {
  pointer-events: all;
  position: fixed;

  top: 5px;
  width: 120px;
  height: 120px;
  margin-left: -60px;

  @include media-breakpoint-up(lg) {
    top: 10px;
    width: 200px;
    height: 200px;
    margin-left: -100px;
  }

  left: 50%;
  color: $white;
  z-index: 1033;
  text-align: center;
  padding: 0;
  transform-origin: top center;
  transition: all 500ms ease-out;

  body.initial & {
    transform: scale(2);
  }

}

@mixin swirl {
  content: '';
  width: 100px;
  height: $navbar-brand-height;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 2px;
  background-position: center;
}

.navbar {
  background: $white;
}

#navbarSupportedContent {
  text-align: center;
  padding-top: 50px;

  @include media-breakpoint-up(lg) {
    padding-top: 0;

    ul {
      width: 50%;

      &:nth-child(1) {
        justify-content: flex-end !important;
        padding-right: 100px;

        &:before {
          background-image: url('../img/swirl-before.svg');
          @include swirl;
        }
      }

      &:nth-child(2) {
        padding-left: 100px;
        margin-left: auto !important;

        &:after {
          background-image: url('../img/swirl-after.svg');
          @include swirl;
        }
      }
    }

  }

  a {
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-900;
    transition: color 100ms linear;
    pointer-events: all;
    @extend .text-highlight;
    padding: 0;
    margin: .5rem;
    white-space: nowrap;

    &:before {
      white-space: nowrap;
    }

  }
  a.dropdown-item {
    margin: 0;
    padding: .5rem;
  }
}
