.overlay-container header {
  @extend .text-highlight;
  font-size: 2rem;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 6rem;
  }

  small {
    margin-left: .6em;
    font-size: .3em;
    display: block;
  }

  h1 {
    line-height: .8em;
    font-size: 1em;
    margin: 0;
    padding: 0;
    letter-spacing: .05em;
  }
}

*[data-line] {
  position: relative;
  z-index: 2;

  &:after {
    content: attr(data-line);
    position: absolute;
    left: .03em;
    top: .04em;
    width: 100%;

    /*background: url(https://subtlepatterns.com/patterns/crossed_stripes.png) repeat;*/
    background-image: -webkit-linear-gradient(left top, transparent 0%, transparent 25%, #555 25%, #555 50%, transparent 50%, transparent 75%, #555 75%);
    background-size: 4px 4px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: -1;
    display: block;
    text-shadow: none;
  }
}
