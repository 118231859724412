.leaflet-control {
  &, & * {
    background-color: $primary !important;
  }
}

.leaflet-bar a, .leaflet-bar a:hover {
  color: $white;
}

.leaflet-control-layers-toggle {
  background-image: url('../img/layers.png');
}

.leaflet-control-layers-list {
  background-color: $dark !important;
}

.leaflet-control-attribution {
  &, & * {
    background-color: rgba(0, 0, 0, .7) !important;
    color: $light;
    opacity: .5;
  }
}

.leaflet-popup-content-wrapper {
  overflow: hidden;
  overflow-clip: border-box;
  width: 264px;
  max-width: 90%;
  border-radius: 5px;
  color: $dark;
  @extend .text-highlight;

  a {
    text-decoration: none;
  }
  img {
    width: 268px;
    transform: translateZ(0px);
    display: none;
    margin: -2px;

    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  header {
    @extend .p-1;
    box-shadow: 0 0 50px rgba(251, 225, 206, .5) inset;
    margin: -1px;

    h4 {
      @extend .py-0;
      text-align: center;
      letter-spacing: .05em;
      line-height: .8em;
    }

    .read {
      display: block;
      text-align: right;
    }

  }
}

.leaflet-popup-content {
  padding: 0;
  margin: 0;
}

.leaflet-popup-content-wrapper a {
  color: $dark;
}

.leaflet-popup-tip {
  background-color: #fef8f4;
}

#map {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  @include media-breakpoint-up(lg) {
    bottom: 40px;
  }
  background: #000;
  transition: filter 1s ease-out;

  body.loading &, body.overlayed & {
    filter: blur(5px);
  }
}

.body,
html {
  height: 100%;
}

body {
  background: #2f2f2f;
}

.pin {
  animation-name: bounce-latest;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pin-latest {
  animation-name: bounce-latest;
  animation-fill-mode: both;
  animation-duration: 1s;
  width: 60px;
  height: 77px;
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -18px;
  background-image: url('../img/dana.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.pulse {
  background: rgba($dark, .7);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 50%;
  top: 53%;
  margin: -10px 0px 0px -10px;
  transform: rotateX(55deg);
  z-index: -2;

  &:after {
    content: "";
    border-radius: 50%;
    height: 46px;
    width: 46px;
    position: absolute;
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    filter: alpha(opacity=0);
    box-shadow: 0 0 1px 2px $dark;
    animation-delay: 1.1s;
  }
}

.marker-cluster .pulse:after {
  margin-left: -20px;
}

@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce-latest {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    filter: none;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(30px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba($white, .9);
  pointer-events: none;
  opacity: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  &.active {
    animation: blur 1s ease-out;
    pointer-events: all;
    opacity: 1;
  }

  .close {
    top: $navbar-brand-height * 2;
    right: 6px;

    @include media-breakpoint-up(lg) {
      top: $navbar-brand-height * 4;
      right: 1em;
    }

    font-size: 3em;
    color: $black;
    position: fixed;
    z-index: 2000;
  }

  .overlay-container {
    padding-top: $navbar-brand-height * 3;
    margin: 0 1em;

    @include media-breakpoint-up(lg) {
      padding-top: $navbar-brand-height * 5;
    }

    padding-bottom: $navbar-brand-height;
    #overlay-content {
      word-wrap: break-word;
      transform: translateZ(0px);
      backface-visibility: hidden;
      @extend .container;
    }
  }
}
