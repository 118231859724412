@import "../../node_modules/bootstrap/scss/bootstrap";
@import "variables";
@import "../../node_modules/leaflet/dist/leaflet";
@import "../../node_modules/gutenberg-sass/dist/style";

$navbar-brand-height-full: $navbar-brand-height + ($navbar-padding-y * 2);

@font-face {
  font-family: 'BakerStreetOblique';
  src: url('../webfonts/BakerStreetOblique.eot'); /* IE9 Compat Modes */
  src: url('../webfonts/BakerStreetOblique.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../webfonts/BakerStreetOblique.woff') format('woff'), /* Modern Browsers */
  url('../webfonts/BakerStreetOblique.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../webfonts/BakerStreetOblique.svg#BakerStreetOblique') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

.text-highlight {
  font-family: 'BakerStreetOblique';
  @extend .text-uppercase;
}

* {
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  @extend .text-highlight;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  font-family: 'Raleway', sans-serif;
  font-weight: 400; // Corresponds to the Bold 400 style
 // -webkit-transform:translate3d(0,0,0);
}
/*
* {
  -webkit-backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -webkit-perspective: 1000;
}
*/

body.home {
  overflow: hidden;
}

#wpadminbar {
  display: none !important;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

body {

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 1031;
    transform: translateZ(0px);
    backface-visibility: hidden;
  }
  &:after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: $primary;
    margin: -20px -20px 0 0;

    animation: sk-rotateplane 1.2s infinite ease-in-out;

    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1031;
    backface-visibility: hidden;
  }

  &:before, &:after {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 1s;
  }
  &.loading:before, &.loading:after {
    pointer-events: all;
  }
  &.loading:after {
    opacity: 1;
  }
  &.loading:before {
    opacity: .7;
  }
  &.initial.loading:before {
    opacity: 1;
  }
}

@import 'modules/map';
@import 'modules/nav-main';
@import 'modules/single';

@import 'modules/nav-footer';
