footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  width: 100%;

  .nav-item a {
    color: $light !important;
    opacity: .3;
    font-size: .7rem !important;
    @extend .text-highlight;
    transition: opacity 500ms linear;

    &:hover {
      opacity: .7;
    }
  }
}
